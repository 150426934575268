import { createRouter, createWebHistory } from "vue-router";
import { useAccountStore } from "@/stores/account";
import { useApiStore } from "@/stores/api";
import Login from "../views/Login.vue";
import AccessDenied from "../views/AccessDenied.vue";
import * as Sentry from "@sentry/vue";
import errorHandling from "@/mixins/errorHandling";

// route level code-splitting
// this generates a separate chunk (Products.[hash].js) for this route
// which is lazy-loaded when the route is visited.
const routes = [
  // Default page
  { path: "/", redirect: { name: "Dashboard" } },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: AccessDenied,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
  },
  { path: "/resetpassword", redirect: { name: "ResetPassword" } },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: () => import("../views/UserProfile.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  { path: "/product-hub", redirect: { name: "Products" } },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/Products.vue"),
  },
  { path: "/product-hub/:isin", redirect: { name: "Product" } },
  {
    path: "/products/:isin",
    name: "Product",
    component: () => import("../views/Product.vue"),
    props: true,
  },
  {
    path: "/dashboard/products/subscriptions/:isin",
    name: "DashboardSubscriptionsProducts",
    component: () => import("../views/Product.vue"),
    props: route => ({
      isin: route.params.isin,
      showGraph: false,
      showEvents: false,
      showAccounts: false,
      showRelatedWorkflows: false,
      showTerms: false,
      showInvoices: false,
      showProductTypeDescription: true,
      showSrfPdd: false,
      showFees: false,
      dashboardProduct: true,
    }),
  },
  {
    path: "/dashboard/products/issued/:isin",
    name: "DashboardIssuedProducts",
    component: () => import("../views/Product.vue"),
    props: route => ({
      isin: route.params.isin,
      showEvents: false,
      showAccounts: false,
      showRelatedWorkflows: false,
      showTerms: false,
      showInvoices: false,
      showSrfPdd: false,
      showFees: false,
      dashboardProduct: true,
    }),
  },
  {
    path: "/workflows/new",
    name: "NewWorkflow",
    component: () => import("../views/NewWorkflow.vue"),
  },
  {
    path: "/workflows/:type?/:id([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})",
    name: "Workflow",
    component: () => import("../views/Workflow.vue"),
    props: true,
  },
  {
    path: "/workflows/:type?/:status(pending|active|complete|terminated|all)?",
    name: "Workflows",
    component: () => import("../views/Workflows.vue"),
    props: true,
  },
  {
    path: "/workflows/:type/batch-process/:status(pending|active|complete|terminated|all|batches)?",
    name: "BatchProcessWorkflows",
    component: () => import("../views/Workflows.vue"),
    props: true,
  },
  {
    path: "/workflows/:type/batch-process/:status(batches)/:id/:step(list)?",
    name: "BatchProcess",
    component: () => import("../views/Workflows.vue"),
    props: true,
  },
  {
    path: "/workflows/:type/:action(create|clone)/:id?",
    name: "CreateWorkflow",
    component: () => import("../views/Workflow.vue"),
    props: true,
  },
  // the /workflow/display/:id path is used by the G2 Portal
  {
    path: "/workflow/display/:id",
    name: "WorkflowDisplay",
    component: () => import("../views/Workflow.vue"),
    props: true,
  },
  {
    path: "/order-acceptance-templates",
    name: "OrderAcceptanceTemplates",
    component: () => import("../views/OrderAcceptanceTemplates.vue"),
  },
  {
    path: "/workflows/order-acceptance-template/:action([a-z]*)/:id([0-9]+)?",
    redirect: { name: "OrderAcceptanceTemplate" },
  },
  {
    path: "/order-acceptance-templates/:action([a-z]*)/:id([0-9]+)?",
    name: "OrderAcceptanceTemplate",
    component: () => import("../views/OrderAcceptanceTemplate.vue"),
    props: true,
  },
  {
    path: "/workflows/order-acceptance-template/:type?/:action([a-z]*)/:id([0-9]+)?",
    redirect: { name: "OrderAcceptanceTemplate" },
  },
  {
    path: "/order-acceptance-templates/:type?/:action([a-z]*)/:id([0-9]+)?",
    name: "OrderAcceptanceTemplate",
    component: () => import("../views/OrderAcceptanceTemplate.vue"),
    props: true,
  },
  {
    path: "/planned-coupons",
    name: "PlannedCoupons",
    component: () => import("../views/PlannedCoupons.vue"),
  },
  {
    path: "/srf",
    name: "SRFRequests",
    component: () => import("../views/SRFRequests.vue"),
  },
  {
    path: "/srf/:id",
    name: "SRF",
    component: () => import("../views/SRF.vue"),
    props: true,
  },
  {
    path: "/srfv2/:id",
    redirect: { name: "SRF" },
  },
  {
    path: "/srf/legacy/:id",
    name: "SRFLegacy",
    component: () => import("../views/SRFLegacy.vue"),
    props: true,
  },
  {
    path: "/srf-lite/:id",
    name: "SRFLite",
    component: () => import("../views/SRFLite.vue"),
    props: true,
  },
  {
    path: "/srf/raw/:srf_id",
    name: "SRFRaw",
    component: () => import("../views/SRFRaw.vue"),
    props: true,
  },
  {
    path: "/srf/pdd/:id/:entityType?/:entityName?",
    name: "PDD",
    component: () => import("../views/PDD.vue"),
    props: true,
  },
  {
    path: "/products/:id/viewtermsheet",
    name: "View Termsheet",
    component: () => import("../views/ViewTermsheet.vue"),
    props: true,
  },
  {
    path: "/impersonate",
    name: "Impersonate",
    component: () => import("../views/Impersonate.vue"),
  },
  {
    path: "/document-hub",
    name: "DocumentHub",
    component: () => import("../views/DocumentHub.vue"),
    children: [
      {
        path: "gentwo-documents",
        name: "NonDigitalDocuments",
        title: "Gentwo Documents",
        showDocumentTypeName: true,
        component: () => import("../views/DocumentHub.vue"),
      },
      {
        path: "gentwo-digital-documents",
        name: "DigitalDocuments",
        title: "Gentwo Digital Documents",
        showDocumentTypeName: true,
        component: () => import("../views/DocumentHub.vue"),
      },
      {
        path: "issuer-kyc-documents",
        name: "IssuerKYCDocuments",
        title: "Issuer KYC Documents",
        showDocumentTypeName: false,
        component: () => import("../views/DocumentHub.vue"),
      }
    ],
  },
  {
    path: "/help-center",
    name: "Support",
    component: () => import("../views/Support.vue"),
    children: [
      {
        path: "faq",
        name: "FAQ",
        title: "FAQs",
        component: () => import("../components/support/FAQ.vue"),
      },
      {
        path: "user-guide",
        name: "UserGuide",
        title: "User Guides",
        component: () => import("../components/support/UserGuide.vue"),
      },
      {
        path: "contacts",
        name: "Contacts",
        title: "Contact us",
        component: () => import("../components/support/Contacts.vue"),
      },
    ],
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: () => import("../views/Onboarding.vue"),
  },
  {
    path: "/onboarding-verification",
    name: "OnboardingSetPassword",
    component: () => import("../views/OnboardingSetPassword.vue"),
    beforeEnter: (to, from, next) => {
      if (to.query.emailconfirmed || (to.query.id && to.query.code)) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    // to handle pages where there's hash navigation
    if (to.hash) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            //top: savedPosition,
            //top: -50,
            behavior: "smooth",
          });
        }, 250);
      });
    } else {
      return {
        top: 0,
        //behavior: "smooth"
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/forgot-password",
    "/resetpassword",
    "/reset-password",
    "/access-denied",
    "/onboarding",
    "/onboarding-verification",
  ];
  const documentHubAllowedPages = [
    "/user-profile",
    "/document-hub",
    "/document-hub/gentwo-documents",
    "/document-hub/gentwo-digital-documents",
  ];
  const authRequired = !publicPages.includes(to.path);
  const accountStore = useAccountStore();
  const loggedIn = accountStore.userLogged;
  const hasGenTwoProAccess = accountStore.user?.hasGenTwoProAccess;
  const hasSrfAccess = !accountStore.user?.isUserPayingAgent && !accountStore.user?.isUserIssuer;
  const isIsp = accountStore.user?.isISP;
  const hasDashboardAccess = accountStore.user?.isUserGentwo || accountStore.user?.isClient;
  const hasDocumentHubAccess = accountStore.user?.hasDocumentHubAccess;
  const hasBatchPaymentsAccess = accountStore.user?.canSeeBatchPayments;

  // trying to access a restricted page + not logged in
  // redirect to login page
  const shouldRedirectToRoot =
    (to.path.startsWith("/onboarding") && loggedIn) ||
    (to.path.startsWith("/srf") &&
      !hasSrfAccess &&
      !(to.path.startsWith("/srf/pdd") && (isIsp || hasSrfAccess)));

  if (authRequired && !loggedIn) {
    next("/login");
  } else if (authRequired && !hasGenTwoProAccess && !hasDocumentHubAccess) {
    next("/access-denied");
  } else if (shouldRedirectToRoot) {
    next("/");
  } else if (
    !documentHubAllowedPages.includes(to.path) &&
    authRequired &&
    !hasGenTwoProAccess &&
    hasDocumentHubAccess
  ) {
    next("/document-hub");
  } else if (
    (!hasDashboardAccess && to.path === "/dashboard") ||
    (!hasBatchPaymentsAccess && to.path.includes("batch-process"))
  ) {
    next("/workflows");
  } else {
    router.pending = to;
    next();
  }

  // If we navigate to a new page, assume we don't care about backend 400 errors from some previous page
  useApiStore().clearBackendErrors();
});

router.onError(error => {
  Sentry.addBreadcrumb({
    category: "debugging",
    message: `In router.onError. Router pending fullpath is ${router.pending?.fullPath}. Error name is ${error.name}`,
    level: "info",
  });

  if (
    router.pending?.fullPath &&
    (errorHandling.methods.isChunkLoadError(error) ||
      errorHandling.methods.isPreloadCSSError(error))
  ) {
    // try to reload the desired view
    window.location.assign(router.pending.fullPath);
  } else {
    // else, just reload
    window.location.reload();
  }
});

export default router;
